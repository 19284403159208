/*jslint vars: true, plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
(function(){
	'use strict';

	$( document ).ready(function() {

		// -------------------------------------------------------
		// OBJECT FIT IMAGES
		objectFitImages();
		// END OBJECT FIT IMAGES

		// -------------------------------------------------------
		// FIXED SCROLL
		var sidebar = $('#sidebar');
		var container = $('.contact');
		var gform = $('.gform_wrapper');
	    var offset = sidebar.offset();
	    var offsetcontainer = container.offset();
	    var topPadding = 0;
	    var topvalue = 80;
	    var bottomvalue = 0;
	    var topOffset = 80;
	    var bottomOffset = 0;
	    var breakpoint = 820;
	    $(window).scroll(function() {
	    	animateFixedScroll();
	    });
	    $(window).resize(function() {
	    	animateFixedScroll();
	    });

	    var gformWidth = gform.width();
	    var gformHeight = gform.height();
	    function animateFixedScroll(){
	    	if (window.innerWidth > breakpoint) {
	    		if ($(window).scrollTop() > offset.top - topOffset) {
		            container.css({
		                'position': 'fixed',
		                'top': topvalue,
		                'bottom': '',
		                'width': 'auto',
		                // 'box-shadow': '-1px 0px 0 #D5D5D5',
		            });
		            gform.css({
		                'width': gformWidth,
		                'height': gformHeight,
		            });
		        } else {
	    			gformWidth = gform.width();
	    			gformHeight = gform.height();
		            container.css({
		                'position': '',
		                'top': '',
			            'bottom': '',
		                'width': 'calc(100% + 3rem)',
		                // 'box-shadow': '',
		            });
		            gform.css({
		                'width': '100%',
		                'height': 'auto',
		            });
		        }
	    	}
	    }
	    // END FIXED SCROLL

	    // -------------------------------------------------------
	    // SCROLL TO ON CLICK
	    $('.menu-item').click(function(){
		    var offset = 130;
	    	var index = $(this).index() + 2;
		    var topOfObject = $( '#' + index ).offset().top;

		    $('html, body').animate({
		    	scrollTop: topOfObject - offset
		    }, 500);
		    return false;
	    });
	    // END FIXED SCROLL

	    // -------------------------------------------------------
	    // SCROLL ANIMATION
	    var navWrapper = $('#nav-wrapper');
	    $(window).scroll(function() {
	    	// $(window).scrollTop();
	    	var scrollTop = $(window).scrollTop();
	    	var transformValue = scrollTop/4;
	    	$('.header .atomium').css({
	    		'transform': 'translateY('+transformValue+'px)'
	    	});

	    	if (scrollTop > 10 ) {
	    		navWrapper.addClass('box-shadow');
	    	} else {
	    		navWrapper.removeClass('box-shadow');
	    	}

	    });
	    // END SCROLL ANIMATION

	    // -------------------------------------------------------
	    // FADE IN ANIMATION
	    $(window).on('beforeunload', function(){
	      $(window).scrollTop(0);
	    });
	    revealElement();
	    $(window).scroll(function () {
	       revealElement();
	    });
	    function revealElement(){
	      $('.inner-animation').each(function () {
	         if (isScrolledIntoView(this) === true) {
	             $(this).removeClass('not-visible not-visible-header');
	         }
	      });
	    }
	    function isScrolledIntoView(elem) {
	        var docViewTop = $(window).scrollTop();
	        var docViewBottom = docViewTop + $(window).height();

	        var elemTop = $(elem).offset().top;
	        // var elemBottom = elemTop + $(elem).height();
	        var elemBottom = $(elem).offset().top+200;

	        // return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	    }
	    // END FADE IN ANIMATION

	    // -------------------------------------------------------
	    // COOKIE
	    function getCookie(cname) {
	        var name = cname + "=";
	        var ca = document.cookie.split(';');

	        for (var i=0; i < ca.length; i++) {
	            var c = ca[i];
	            while (c.charAt(0)==' ') c = c.substring(1);
	            if (c.indexOf(name) === 0) return  c.substring(name.length,c.length);
	        }

	        return false;
	    }

	    function setCookie(cname, cvalue) {
	    	var d = new Date();
	        d.setTime(d.getTime() + (9000*24*60*60*1000));
	        var expires = "expires="+d.toUTCString();
	        
	        document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/;secure;";  //secure werkt enkel over https 
	    }

	    $('#cookie-close').click(function() {
	    	$('#cookie-banner').hide('fast');

	    	setCookie('visited', 'true');
	    });

	    // -------------------------------------------------------
	    // POPUP
	    $('#popup-close').click(function() {
	    	$('#popup').removeClass('visible');
	    });

	    // --------------------------------------------------------------------
	    // HAMBURGER / NAV
	    $('.hamburger').click(function() {
	    	$('body').toggleClass('stop-scrolling');
	    	$(this).toggleClass('is-active');
	    	$('.nav-menu').toggleClass('show-nav');
	    });
	    $('nav #left-container ul a').click(function() {
	    	$('body').removeClass('stop-scrolling');
	    	$('.hamburger').removeClass('is-active');
	    	$('.nav-menu').removeClass('show-nav');
	    });
	    $(window).on('resize',function() {
	    	var windowsize = $(window).width();
	    	if(windowsize >= 960) {
	    		$('body').removeClass('stop-scrolling');
	    		$('.hamburger').removeClass('is-active');
	    		$('.menu-container').removeClass('show-nav');
	    	}
	    });
	    
	});

})(); // END OF USE STRICT FUNCTION